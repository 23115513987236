/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Title, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"www.fit-okna.cz"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-mi8rvp pb--10 pl--25 pr--25 pt--10" name={"[[T00name]]"} layout={"l1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/40398/31137bebefb84f72a8379ef90012076e_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" alt={""} src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} content={null} RootClassName={"column__pic"}>
              </Image>

              <Title className="title-box" style={{"maxWidth":688,"marginTop":76,"marginBottom":4}} content={"<span style=\"color: var(--white);\">Montáž a servis žaluzí<br>Zaměření a instalace rolet a sítí proti hmyzu<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kn44rz5kaug"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Interiérové žaluzie<br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"font-style: italic;\">Široký výběr žaluzií a barevných variací</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--center" content={"Isotra"}>
              </Subtitle>

              <Text className="text-box text-box--center fs--30" style={{"maxWidth":""}} content={"Naše nabídka vnitřních žaluzií, je složena z několika různých produktových řad, přičemž každá má své určení a specifika. <br><br>Pro všechny produktové řady interiérového zastínění je však společná vysoká kvalita, moderní design a přijatelná cena. <br><br>Naše společnost své produkty neustále inovuje a přizpůsobuje potřebám zákazníků.<br><br>Vždy se snažíme nabídnout nejlepší řešení k Vaší plné spokojenosti . . .&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/03f4855cbd4747479846168159bd6cee_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/03f4855cbd4747479846168159bd6cee_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box fs--30" content={"Isotra system classic"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/29201d2fc2fd47c58c7b0921356ada30_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/29201d2fc2fd47c58c7b0921356ada30_s=350x_.jpg 350w, https://cdn.swbpg.com/t/40398/29201d2fc2fd47c58c7b0921356ada30_s=660x_.jpg 660w, https://cdn.swbpg.com/t/40398/29201d2fc2fd47c58c7b0921356ada30_s=860x_.jpg 860w, https://cdn.swbpg.com/t/40398/29201d2fc2fd47c58c7b0921356ada30_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/40398/29201d2fc2fd47c58c7b0921356ada30_s=2000x_.jpg 2000w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Isotra Energy"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/b2865334cc0f4a4f91c13e4aa816e255_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/b2865334cc0f4a4f91c13e4aa816e255_s=350x_.png 350w, https://cdn.swbpg.com/t/40398/b2865334cc0f4a4f91c13e4aa816e255_s=660x_.png 660w, https://cdn.swbpg.com/t/40398/b2865334cc0f4a4f91c13e4aa816e255_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Isotra system classic"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"k4i89qbpai"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Rolety a sítě proti hmyzu"}>
              </Title>

              <Text className="text-box text-box--center fs--30" style={{"maxWidth":650}} content={"Stačí v průběhu letního větrání na moment rozsvítit v místnosti světlo a pokoj je plný hmyzu.\n<br><br>&nbsp;Sítě proti hmyzu do oken tento problém spolehlivě odstraní.\n<br><br>&nbsp;Máte-li nová okna bez okapnic, budou se vám hodit sítě proti komárům, které se nepřivrtávají do rámů.\n<br><br>&nbsp;Starší typy oken s okapnicemi ochráníte síťkami proti hmyzu s pružinovými kolíky, případně obrtlíky, které se naopak přivrtat musí.\n<br><br>&nbsp;Do třetice je vám k dispozici rolovací síť proti mouchám, která vyžaduje pouze drobný zásah do okenní konstrukce.\n<br><br>&nbsp;Rovněž dveře na balkon, na terasu a do zahrady nezapomeňte na teplé období roku vybavit sítěmi proti hmyzu na míru.\n<br><br>&nbsp;Odolná síťovina je vypnutá v kvalitním hliníkovém rámu, který je vybaven samozavíracími panty.\n<br><br>&nbsp;Pro dveře (na balkon, nebo terasu) je vhodný typ sítě proti komárům H1, který je snadno demontovatelný. <br><br>Tam, kde není dostatečný prostor pro jakýkoliv jiný druh sítí proti hmyzu, je vhodné zvolit plisovanou síť proti hmyzu.\n<br><br>&nbsp;Do dveří na lodžii, na terasu nebo na zahradu - všude tam oceníte nízkoprofilové plisované sítě proti hmyzu. <br><br>Též do balkonových dveří se hodí tato ochrana proti mouchám a komárům.\n<br><br>&nbsp;Stačí si vybrat vhodnou síť proti hmyzu podle toho, zda se má otevírat vertikálně či horizontálně.\n<br><br>&nbsp;Plisé sítě proti komárům navíc zlepšují tepelnou a zvukovou izolaci budovy."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"1l7t9eoz9ue"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/dff974bae4eb43b4ae52960fd6a8e1d6_s=660x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/dff974bae4eb43b4ae52960fd6a8e1d6_s=350x_.png 350w, https://cdn.swbpg.com/t/40398/dff974bae4eb43b4ae52960fd6a8e1d6_s=660x_.png 660w, https://cdn.swbpg.com/t/40398/dff974bae4eb43b4ae52960fd6a8e1d6_s=860x_.png 860w"} position={null}>
              </Image>

              <Title className="title-box fs--36" content={"Isotra tip C big"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Jak úspěšně bojovat proti létajícímu hmyzu? Kombinací vhodné interiérové nebo exteriérové stínicí techniky se sítí proti hmyzu. Sítě typu C se řadí do skupiny okenních sítí, jejichž montáž nevyžaduje žádné zásahy do rámu okna a lze je snadno namontovat a demontovat z okenního rámu pomocí otočných klipů. Jednotlivé typy sítí typu C se liší tvarem a velikostí profilu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/ba188b27cb0f42dcbf17114830ccdd8f_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/ba188b27cb0f42dcbf17114830ccdd8f_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box" content={"Isotra tip C"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Praktická síťka proti hmyzu vhodná především do starších typů oken nebo do stavebních otvorů. Sítě typu E jsou předchůdci novějších typů okenních sítí proti hmyzu. Síťka proti hmyzu se upevňuje na okno pomocí obrtlíků a vyžaduje navrtání sítě do okenního rámu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/40398/afcb00ed8a87484aacb5e8652d86d21e_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/40398/afcb00ed8a87484aacb5e8652d86d21e_s=350x_.png 350w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Dveřní sítě proti hmyzu typ H1"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Dveřní sítě proti hmyzu vás ochrání před nezvaným hmyzem všeho druhu. Dveřní síť H1 je praktickou a téměř nezbytnou ochranou proti hmyzu v místnostech s frekventovaným vstupem na terasu či balkón. Dveřní síť H1 je určena jak do balkónových dveří, tak do oken větších rozměrů. Předností této dveřní sítě proti hmyzu je princip samozavírání."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"wblwi32jvta"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--shape4" content={"Kontaktní formulář&nbsp;"} use={"page"} href={"/kontakt-1#[[T00name]]"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"ejqy133lerp"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}